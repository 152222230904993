<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Pedidos</h4>
          <order-search-input-filter
            :is-voading-search="isLoadingSearch"
            @search:input="search = $event"
          />
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="orders"
            :fields="fields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
            mouse-pointer
            @row-clicked="changeRoute"
          >
            <template #cell(active)="row">
              {{ row.item.active ? "Ativo" : "Desativado" }}
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                title="Imprimir pedido"
                @click="getOrderPDFToPrint(row.item.orderId)"
              >
                <b-icon icon="printer" />
              </b-button>

              <send-client-mail
                :order-id="row.item.orderId"
                :client-name="row.item.client.name"
              />
              <confirm-payment-order
                v-if="row.item.statusPanel.description === 'Pedido Realizado'"
                :order-id="row.item.orderId"
                :mercado-pago-id="row.item.mercadoPagoId"
                @status="changeOrderState"
              />
              <upload-xml-pdf-order
                v-if="
                  row.item.statusPanel.description === 'Pagamento Confirmado'
                "
                :order-id="row.item.orderId"
                @status="changeOrderState"
              />
              <confirm-packaged-order
                v-if="
                  row.item.statusPanel.description ===
                  'Nota Fiscal Eletrônica Emitida'
                "
                :order-id="row.item.orderId"
                @status="changeOrderState"
              />
              <inform-shipping-company
                v-if="row.item.statusPanel.description === 'Pedido Embalado'"
                :order-id="row.item.orderId"
                @status="changeOrderState"
              />
              <confirm-order-delivered
                v-if="
                  row.item.statusPanel.description ===
                  'Entregue a Transportadora'
                "
                :order-id="row.item.orderId"
                @status="changeOrderState"
              />
            </template>
            <template #cell(createdAt)="row">
              {{ new Date(row.item.createdAt).toLocaleString("pt-BR") }}
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ConfirmOrderDelivered from "../../components/order/ConfirmOrderDelivered.vue";
import ConfirmPackagedOrder from "../../components/order/ConfirmPackagedOrder.vue";
import ConfirmPaymentOrder from "../../components/order/ConfirmPaymentOrder.vue";
import InformShippingCompany from "../../components/order/InformShippingCompany.vue";
import OrderSearchInputFilter from "../../components/order/OrderSearchInputFilter.vue";
import SendClientMail from "../../components/order/SendClientMail.vue";
import UploadXmlPdfOrder from "../../components/order/UploadXmlPdfOrder.vue";
import http from "../../plugins/http";

export default {
  components: {
    OrderSearchInputFilter,
    UploadXmlPdfOrder,
    ConfirmPaymentOrder,
    ConfirmPackagedOrder,
    InformShippingCompany,
    ConfirmOrderDelivered,
    SendClientMail,
  },
  name: "listOrders",
  data() {
    return {
      page: 2,
      search: "",
      modalMessage: "",
      showModal: false,
      productToDeactvate: {},
      fields: [
        {
          key: "orderId",
          label: "N° Pedido",
          sortable: true,
        },
        {
          key: "statusPanel.description",
          label: "Situação",
          sortable: true,
        },
        {
          key: "client.name",
          label: "Cliente",
          sortable: true,
        },
        {
          key: "city",
          label: "Cidade",
          sortable: true,
        },
        {
          key: "state",
          label: "UF",
          sortable: true,
        },
        {
          key: "paymentMethod",
          label: "Forma de Pagamento",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Data/Hora",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
    };
  },
  computed: {
    ...mapState("orders", [
      "orders",
      "canceled",
      "isLoading",
      "isLoadingSearch",
      "loadMore",
    ]),
    ...mapGetters("auth", ["userPermission"]),

    hideButton() {
      return this.userPermission([2, 3]);
    },
  },
  methods: {
    ...mapActions("orders", [
      "getAllOrders",
      "getAllCanceled",
      "getMoreAllOrders",
      "searchOrders",
      "getMoreSearchOrders",
      "getOneOrder",
    ]),
    ...mapMutations("orders", ["clearOrderValues", "changeOrderState"]),
    ...mapMutations(["setToastedSuccess", "setToastedError", "setOverlay"]),

    async getCanceledOrder() {
      await this.getAllCanceled();
    },

    async changeRoute({ orderId }) {
      this.clearOrderValues();
      await this.getOneOrder(orderId);
      this.$router.push(`pedidos/${orderId}/editar`);
    },
    async getOrderPDFToPrint(orderId) {
      this.setOverlay(true);
      try {
        const url = `/ecommerce/order/generate-order-pdf/${orderId}`;
        const { data } = await http.get(url, { responseType: "blob" });

        const pdfBlob = URL.createObjectURL(data);
        window.open(pdfBlob, "_blank");
        this.setToastedSuccess({ message: "PDF gerado com sucesso" });
      } catch (err) {
        const error = JSON.parse(await err.response.data.text()).error;
        this.setToastedError({
          message: error || "Erro ao PDF para impressão",
        });
      }
      this.setOverlay(false);
    },
    async getMore() {
      const { page, search } = this;
      if (!this.loadMore) return;

      if (search) {
        await this.getMoreSearchOrders({ page, search });
      } else {
        await this.getMoreAllOrders(page);
      }

      this.page++;
    },
  },
  async created() {
    await this.getAllOrders();
  },
};
</script>

<style scoped>
.apeu-search-input {
  width: 350px;
}
</style>
