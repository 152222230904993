<template>
  <b-button
    variant="primary"
    size="sm"
    class="mr-2"
    title="Nf-e emitida"
    @click="showFormModal = true"
  >
    <b-icon icon="file-earmark-text" />
    <b-modal v-model="showFormModal" hide-header hide-footer size="lg">
      <b-form>
        <b-form-group v-if="showInput" label="XML e PDF:">
          <b-form-file
            v-model="xmlToUpload"
            placeholder="Selecione o XML e o PDF"
            accept="application/xml, application/pdf"
            multiple
            @input="uploadXMLPDF"
          />
        </b-form-group>

        <div v-if="showSpinner" class="d-flex justify-content-center">
          <b-spinner class="my-2" />
        </div>
        <div v-else>
          <div v-if="!showResult" class="text-center">
            <span class="h4 text-danger font-weight-bold">
              Atenção, após o envio não será possível modifiar os arquivos
            </span>
          </div>
          <div v-else>
            <b-form-group label="Chave de acesso:">
              <b-form-input :value="form.accessKey" disabled />
            </b-form-group>
            <b-form-group label="Número:">
              <b-form-input :value="form.number" disabled />
            </b-form-group>
            <b-form-group label="Serie:">
              <b-form-input :value="form.serie" disabled />
            </b-form-group>
            <b-form-group label="Data de emissão:">
              <b-form-input
                :value="new Date(form.emissionDate).toLocaleDateString('pt-BR')"
                disabled
              />
            </b-form-group>
            <b-form-group label="Link do PDF:">
              <b-form-input :value="form.linkPDF" disabled />
            </b-form-group>
            <b-form-group label="Link do XML:">
              <b-form-input :value="form.linkXML" disabled />
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </b-button>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

export default {
  name: "UploadXmlPdfOrder",
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      url: `/ecommerce/order/uploadNfe/${this.orderId}`,
      showFormModal: false,
      xmlToUpload: null,
      form: {
        number: null,
        serie: null,
        accessKey: "",
        emissionDate: "",
        linkXML: "",
        linkPDF: "",
      },
      showResult: false,
      showSpinner: false,
      showInput: true,
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    createFormData() {
      const { xmlToUpload } = this;
      const formData = new FormData();

      for (const upload of xmlToUpload) {
        if (upload.type === "application/pdf") {
          formData.append("pdf", upload);
        }
        if (upload.type == "text/xml" || upload.type == "application/xml") {
          formData.append("xml", upload);
        }
      }

      return formData;
    },
    async uploadXMLPDF() {
      this.showSpinner = true;
      try {
        const formData = this.createFormData();
        const { data } = await http.post(this.url, formData);
        this.form = data;

        this.showResult = true;
        this.showInput = false;
        this.setToastedSuccess({
          message: "Os arquivos da NF-e foram enviados com sucesso",
        });
        this.$emit("status", {
          id: this.orderId,
          status: "Nota Fiscal Eletrônica Emitida",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
      this.showSpinner = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
