<template>
  <b-button
    variant="primary"
    size="sm"
    class="mr-2"
    title="Entrega Realizada com Sucesso"
    @click="showModal = true"
  >
    <b-icon icon="check2" />
    <b-modal
      v-model="showModal"
      hide-footer
      size="lg"
      title="Confirmar entrega"
    >
      <p>
        Deseja confirmar a entrega do pedido? Esta ação não poderá ser desfeita
      </p>
      <b-button @click="confirmOrderDelivered">Confirmar</b-button>
    </b-modal>
  </b-button>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

export default {
  name: "ConfirmOrderDelivered",
  props: {
    orderId: {
      type: Number,
      requried: true,
    },
  },
  data() {
    return {
      showModal: false,
      url: `/ecommerce/order/confirmDelivery/${this.orderId}`,
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async confirmOrderDelivered(event) {
      event.preventDefault();
      try {
        await http.post(this.url);
        this.setToastedSuccess({ message: "Pedido entregue com sucesso" });
        this.$emit("status", {
          id: this.orderId,
          status: "Pedido Entregue",
        });
      } catch (err) {
        this.setToastedError({ message: err.response.data.error });
      }
    },
  },
};
</script>

<style></style>
