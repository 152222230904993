<template>
  <b-button
    variant="primary"
    size="sm"
    class="mr-2"
    title="Entregue a transportadora"
    @click="getTrakingInfo"
  >
    <b-icon icon="truck" />
    <b-modal
      v-model="showModal"
      hide-footer
      size="lg"
      title="Confirmar entrega a transportadora"
    >
      <b-form @submit.prevent="addShippingInfoAndChangeStatus">
        <form-input
          v-model="form.name"
          label="Transportadora"
          icon="truck"
          disabled
        />

        <form-input
          v-model="form.code"
          label="Código de rastreio:"
          placeholder="Digite a código de rastreio"
          icon="code-slash"
          :errors="errors['code']"
          required
        />

        <form-input
          v-model="form.link"
          label="Link de rastreio: (Opcional)"
          placeholder="Digite o link de rastreio"
          icon="link"
          :errors="errors['link']"
        />

        <b-button type="submit">Confirmar</b-button>
      </b-form>
    </b-modal>
  </b-button>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

import FormInput from "../form/FormInput.vue";

export default {
  name: "InformShippingCompany",
  components: { FormInput },
  props: {
    orderId: {
      type: Number,
      requried: true,
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        name: null,
        code: "",
        link: "",
      },
      companies: [],
      url: `/ecommerce/order/addShippingInfo/${this.orderId}`,
      errors: {},
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async addShippingInfoAndChangeStatus() {
      try {
        await http.post(this.url, {
          orderId: this.form.orderId,
          code: this.form.code,
          link: this.form.link,
        });
        this.setToastedSuccess({ message: "Informações de envio adicionadas" });
        this.$emit("status", {
          id: this.orderId,
          status: "Entregue a Transportadora",
        });
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({ message: err.response.data.error });
        }
      }
    },
    async getTrakingInfo() {
      const url = `/ecommerce/order/trackingInfo/${this.orderId}`;
      const { data } = await http.get(url);
      this.form = data;
      this.showModal = true;
    },
  },
};
</script>

<style></style>
