<template>
  <b-button
    variant="primary"
    size="sm"
    class="mr-2"
    title="Confirmação de pagamento"
    @click="getMpInfo"
  >
    <b-icon icon="currency-dollar"></b-icon>
    <b-modal
      v-model="showModal"
      hide-footer
      size="xl"
      title="Confirmação de pagamento"
    >
      <p>
        Atenção, após a confirmação do pagamento não será possível voltar atrás
      </p>
      <h4 class="mb-4">Informações do MercadoPago</h4>
      <b-table
        :items="mpInfo"
        :fields="paymentsFields"
        title="Informações do Mercado Pago"
      >
        <template #cell(status)="data">
          <span v-if="data.item.status === 'pending'">
            O usuário ainda não concluiu o processo de pagamento.
          </span>
          <span v-if="data.item.status === 'approved'">
            O pagamento foi aprovado e creditado.
          </span>
          <span v-if="data.item.status === 'authorized'">
            O pagamento foi autorizado, mas ainda não foi capturado.
          </span>
          <span v-if="data.item.status === 'in_process'">
            O pagamento está sendo analisado.
          </span>
          <span v-if="data.item.status === 'in_mediation'">
            Os usuários iniciaram uma disputa.
          </span>
          <span v-if="data.item.status === 'rejected'">
            O pagamento foi recusado. O usuário pode tentar novamente o
            pagamento.
          </span>
          <span v-if="data.item.status === 'cancelled'">
            O pagamento foi cancelado por uma das partes ou porque o prazo de
            pagamento expirou
          </span>
          <span v-if="data.item.status === 'refunded'">
            O pagamento foi devolvido ao usuário.
          </span>
          <span v-if="data.item.status === 'charged_back'">
            Pagamento estornado no cartão de crédito do comprador.
          </span>
        </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <b-button v-if="cancelButton" class="mr-2" @click="cancelOrder"
          >CANCELAR PEDIDO</b-button
        >
        <b-button
          v-if="disableButton"
          variant="primary"
          @click="changeOrderStatus"
        >
          CONFIRMAR PEDIDO
        </b-button>
      </div>
    </b-modal>
  </b-button>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

export default {
  name: "ConfirmPaymentOrder",
  props: {
    orderId: {
      type: Number,
      requried: true,
    },
    mercadoPagoId: {
      type: String,
      requried: true,
    },
  },
  data() {
    return {
      showModal: false,
      url: `/ecommerce/order/confirmPayment/${this.orderId}`,
      urlMp: `/payment/checkMpOrder/${this.mercadoPagoId}`,
      urlCalcelOrder: `/ecommerce/order/cancelOrder/${this.orderId}`,
      mpInfo: {},
      disableButton: false,
      cancelButton: true,
      paymentsFields: [
        {
          key: "total_paid_amount",
          label: "Total pago",
          sortable: true,
        },
        {
          key: "financial_institution",
          label: "Instituição financeira",
          sortable: true,
        },
        {
          key: "date_of_expiration",
          label: "Data para cancelamento",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
      ],
    };
  },

  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async getMpInfo() {
      try {
        const { data } = await http.get(this.urlMp);
        if (data[0].status === "approved") {
          this.disableButton = true;
        }
        this.mpInfo = data.map((value) => ({
          ...value,
          date_of_expiration: value.date_of_expiration
            ? new Date(value.date_of_expiration).toLocaleDateString("pt-BR")
            : "-",
          total_paid_amount: value.total_paid_amount.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
        }));

        if (
          data[0].status === "cancelled" ||
          new Date(data[0].date_of_expiration) < new Date()
        ) {
          this.cancelButton = true;
        }
      } catch (err) {
        this.disableButton = false;
      }
      this.showModal = true;
    },
    async changeOrderStatus() {
      try {
        await http.post(this.url);
        this.setToastedSuccess({
          message: "O status do pedido foi alterado com sucesso",
        });
        this.$emit("status", {
          id: this.orderId,
          status: "Pagamento Confirmado",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
      this.showModal = false;
    },

    async cancelOrder() {
      try {
        await http.put(this.urlCalcelOrder);
        this.setToastedSuccess({
          message: "O status do pedido foi alterado com sucesso",
        });
        this.$emit("status", {
          id: this.orderId,
          status: "Pedido Cancelado",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
        this.showModal = false;
      }
      this.showModal = false;
    },
  },
};
</script>

<style></style>
