<template>
  <b-button
    variant="primary"
    size="sm"
    class="mr-2"
    title="Enviar E-mail"
    @click="showModal = true"
  >
    <b-icon icon="envelope" />
    <b-modal
      v-model="showModal"
      size="lg"
      :title="`Enviar e-mail ao cliente ${clientName}`"
      hide-footer
    >
      <b-form @submit="sendMail">
        <form-input
          v-model="form.subject"
          label="Assunto:"
          placeholder="Assunto do email"
          icon="envelope"
          :errors="errors['subject']"
          spellcheck
          required
        />

        <form-textarea
          v-model="form.message"
          label="Mensagem:"
          placeholder="Conteúdo da mensagem"
          icon="chat-left-text"
          :errors="errors['message']"
          spellcheck
          required
        />

        <b-button type="submit">Enviar</b-button>
      </b-form>
    </b-modal>
  </b-button>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

import FormInput from "../form/FormInput.vue";
import FormTextarea from "../form/FormTextarea.vue";

export default {
  name: "SendClientMail",
  components: { FormInput, FormTextarea },
  props: {
    orderId: {
      type: Number,
      requried: true,
    },
    clientName: {
      type: String,
      requried: true,
    },
  },
  data() {
    return {
      showModal: false,
      url: `/ecommerce/order/sendEmail/${this.orderId}`,
      form: {
        subject: `${this.clientName} vamos lhe atualizar sobre o pedido ${this.orderId}`,
        message: "",
      },
      errors: {},
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async sendMail(event) {
      event.preventDefault();
      try {
        await http.post(this.url, this.form);
        this.setToastedSuccess({ message: "E-mail enviado com sucesso" });
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({ message: err.response.data.error });
        }
      }
    },
  },
};
</script>

<style></style>
