<template>
  <b-button
    variant="primary"
    size="sm"
    class="mr-2"
    title="Pedido embalado"
    @click="showFormModal = true"
  >
    <b-icon icon="box-seam" />
    <b-modal
      v-model="showFormModal"
      hide-footer
      size="lg"
      title="Embalagem do Pedido"
    >
      <p>Deseja emitir a etiqueta e finalizar a embalagem do produto?</p>
      <b-button @click="getPDFTag">Emitir etiqueta</b-button>
    </b-modal>
  </b-button>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
export default {
  name: "ConfirmPackagedOrder",
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showFormModal: false,
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async getPDFTag() {
      try {
        const url = `/ecommerce/order/generate-tag/${this.orderId}`;
        const { data } = await http.get(url, { responseType: "blob" });

        const pdfBlob = URL.createObjectURL(data);
        window.open(pdfBlob, "_blank");

        this.$emit("status", {
          id: this.orderId,
          status: "Pedido Embalado",
        });
      } catch (err) {
        const error = JSON.parse(await err.response.data.text()).error;
        this.setToastedError({
          message: error || "Erro ao gerar danfe",
        });
      }
    },
  },
};
</script>

<style></style>
