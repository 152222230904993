<template>
  <div
    class="search-input"
    :style="{ width: `${searchInputWidth}px` }"
    @keydown.esc="hideSubMenu"
  >
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon
          icon="arrow-clockwise"
          animation="spin"
          v-if="isLoadingSearch"
        />
        <b-icon icon="search" v-else />
      </b-input-group-prepend>
      <b-form-input
        v-model="search"
        @click="showSubMenu"
        @keydown.enter="makeSearch"
      ></b-form-input>
    </b-input-group>
    <div
      class="apeu-filter-modal rounded-bottom shadow"
      :style="{ display: isSubMenuOpen ? 'block' : 'none' }"
    >
      <b-form>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-around" style="gap: 1rem">
            <b-form-group label="Período inicial:">
              <b-form-datepicker
                v-model="query.firstPeriod"
                size="sm"
                label-no-date-selected="Selecione um data"
                @input="
                  changeQueryValue({ value: $event, objectKey: 'firstPeriod' });
                  makeSearch();
                "
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
            <b-form-group label="Período final:">
              <b-form-datepicker
                v-model="query.finalPeriod"
                size="sm"
                label-no-date-selected="Selecione um data"
                @input="
                  changeQueryValue({ value: $event, objectKey: 'finalPeriod' });
                  makeSearch();
                "
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
            <b-form-group label="Situação:">
              <b-form-select
                v-model="query.situation"
                size="sm"
                :options="situationOptions"
                @change="
                  changeQueryValue({ value: $event, objectKey: 'situation' });
                  makeSearch();
                "
              />
            </b-form-group>
            <b-form-group label="Forma de pagamento:">
              <b-form-select
                v-model="query.paymentMethod"
                size="sm"
                :options="paymentMethodOptions"
                @change="
                  changeQueryValue({
                    value: $event,
                    objectKey: 'paymentMethod',
                  });
                  makeSearch();
                "
              />
            </b-form-group>
          </div>
          <b-button
            @click="
              clearQueryValues();
              makeSearch();
            "
            >Limpar Filtros</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "OrderSearchInputFilter",
  props: {
    isLoadingSearch: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      search: "",
      firstPeriod: "",
      finalPeriod: "",
      situation: "",
      paymentMethod: "",
      isSubMenuOpen: false,
      searchInputWidth: 350,
      situationOptions: [
        "Pedido Realizado",
        "Pagamento Confirmado",
        "Nota Fiscal Eletrônica Emitida",
        "Entregue a Transportadora",
        "Pedido Entregue",
        "Cancelamento Solicitado",
        "Pedido Cancelado",
        "Pedido Embalado",
      ],
      paymentMethodOptions: [
        "Pix",
        "Boleto Bancário",
        "Cartão de Crédito",
        "Cartão de Débito",
      ],
    };
  },
  computed: {
    ...mapState("orders", ["query"]),
  },
  methods: {
    ...mapActions("orders", ["searchOrders", "getAllOrders"]),
    ...mapMutations("orders", ["changeQueryValue", "clearQueryValues"]),

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    hideSubMenu() {
      if (this.isSubMenuOpen) {
        this.isSubMenuOpen = false;
        this.searchInputWidth = 350;
      }
    },
    async showSubMenu() {
      if (!this.isSubMenuOpen) {
        for (
          let currentNumber = 0;
          currentNumber < 200;
          currentNumber = currentNumber + 10
        ) {
          this.searchInputWidth = this.searchInputWidth + 25;
          await this.sleep(0.1);
        }
        this.isSubMenuOpen = true;
      }
    },
    async makeSearch() {
      if (this.search) {
        await this.searchOrders(this.search);
      } else {
        await this.getAllOrders();
      }
      this.$emit("search:input", this.search);
      this.hideSubMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  position: relative;
}

.apeu-filter-modal {
  position: absolute;
  z-index: 50;
  left: 0px;
  width: 100%;
  padding: 0.5rem;

  background-color: #ffff;
}
</style>
