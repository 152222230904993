var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mb-5",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-between mb-4"},[_c('h4',[_vm._v("Pedidos")]),_c('order-search-input-filter',{attrs:{"is-voading-search":_vm.isLoadingSearch},on:{"search:input":function($event){_vm.search = $event}}})],1),_c('b-card-text',{staticClass:"d-flex flex-column"},[_c('b-table',{attrs:{"items":_vm.orders,"fields":_vm.fields,"busy":_vm.isLoading || _vm.isLoadingSearch,"bordered":"","striped":"","hover":"","mouse-pointer":""},on:{"row-clicked":_vm.changeRoute},scopedSlots:_vm._u([{key:"cell(active)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.active ? "Ativo" : "Desativado")+" ")]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"sm","title":"Imprimir pedido"},on:{"click":function($event){return _vm.getOrderPDFToPrint(row.item.orderId)}}},[_c('b-icon',{attrs:{"icon":"printer"}})],1),_c('send-client-mail',{attrs:{"order-id":row.item.orderId,"client-name":row.item.client.name}}),(row.item.statusPanel.description === 'Pedido Realizado')?_c('confirm-payment-order',{attrs:{"order-id":row.item.orderId,"mercado-pago-id":row.item.mercadoPagoId},on:{"status":_vm.changeOrderState}}):_vm._e(),(
                row.item.statusPanel.description === 'Pagamento Confirmado'
              )?_c('upload-xml-pdf-order',{attrs:{"order-id":row.item.orderId},on:{"status":_vm.changeOrderState}}):_vm._e(),(
                row.item.statusPanel.description ===
                'Nota Fiscal Eletrônica Emitida'
              )?_c('confirm-packaged-order',{attrs:{"order-id":row.item.orderId},on:{"status":_vm.changeOrderState}}):_vm._e(),(row.item.statusPanel.description === 'Pedido Embalado')?_c('inform-shipping-company',{attrs:{"order-id":row.item.orderId},on:{"status":_vm.changeOrderState}}):_vm._e(),(
                row.item.statusPanel.description ===
                'Entregue a Transportadora'
              )?_c('confirm-order-delivered',{attrs:{"order-id":row.item.orderId},on:{"status":_vm.changeOrderState}}):_vm._e()]}},{key:"cell(createdAt)",fn:function(row){return [_vm._v(" "+_vm._s(new Date(row.item.createdAt).toLocaleString("pt-BR"))+" ")]}}])}),_c('b-button',{on:{"click":_vm.getMore}},[(_vm.isLoading)?_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin"}}):_vm._e(),_vm._v(" CARREGAR MAIS ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }